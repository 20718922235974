import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {ecomAppDefID} from '../constants';

export async function adiMissingPagesMigration(sdk: IEditorSdk, appToken: string) {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId(appToken, ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll();
  const storesPages = allSitePages.filter(page => page.tpaApplicationId === tpaApplicationId);
  const existsMandatoryPages = await Promise.all(
    storesPages.map(page => {
      const pageRef = {id: page.id, type: page.type};
      return sdk.pages.data.get(appToken, {
        pageRef,
      });
    })
  ).then((pages: any) => {
    return pages.map(pageData => pageData.tpaPageId);
  });

  [PageMap.CART, PageMap.PRODUCT, PageMap.THANKYOU].forEach(async mandatoryPage => {
    if (!existsMandatoryPages.includes(mandatoryPage)) {
      await sdk.tpa.add.component(appToken, {
        appDefinitionId: ecomAppDefID,
        componentType: 'PAGE',
        page: {
          pageId: mandatoryPage,
        },
      });
    }
  });
}
